import { toast } from 'react-toastify';
import _ from 'lodash';

class Notification {
    static show(type, message) {
        if (_.isEmpty(message)) return;

        this.clearAll();

        switch (type) {
            case 'success':
                toast.success(message, { position: toast.POSITION.TOP_RIGHT, theme: 'colored' });
                break;
            case 'error':
                toast.error(message, { position: toast.POSITION.TOP_RIGHT, theme: 'colored' });
                break;
            case 'warning':
                toast.warn(message, { position: toast.POSITION.TOP_RIGHT, theme: 'colored' });
                break;
            case 'info':
                toast.info(message, { position: toast.POSITION.TOP_RIGHT, theme: 'colored' });
                break;
            default:
                break;
        }
    }

    static clearAll() {
        toast.dismiss();
    }
}

export default Notification;