import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import { v4 as uuidv4 } from "uuid";
import { getTime, getDate, getCurrentUTCTime } from "../utils/Helpers";
import ChatService from "../services/chatService";
import Notification from "../utils/notification";
import { getSessionToken } from "../utils/auth";

export default function ChatContainer({ currentChat, socket, currentUser }) {

  const selectedChat = useRef(currentChat);
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);

  useEffect(() => {
    ChatService.getMessage({
      from: currentUser.user_profile_id,
      to: currentChat.user_profile_id
    })
      .then(response => {
        selectedChat.current = currentChat;
        setMessages(response.data.data);
      })
      .catch(error => {
        if (error.response && error.response.status) {
          Notification.show('error', error.response.data.message);
          console.log(error.response.data)
        }
      });
  }, [currentChat]);

  const messageFormat = (messages) => {
    // this gives an object with dates as keys
    const groups = messages.reduce((groups, message) => {
      const date = message.created ? message.created.split('T')[0] : getCurrentUTCTime();
      if (!groups[date]) {
        groups[date] = [];
      }
      if (!message.created) {
        message.created = getCurrentUTCTime();
      }
      groups[date].push(message);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        messages: groups[date]
      };
    });

    return groupArrays;
  }

  const handleSendMsg = async (msg) => {
    const data = await getSessionToken();

    ChatService.storeMessage({
      from: data.user_profile_id,
      to: selectedChat.current.user_profile_id,
      message: msg
    })
      .then(response => {
        socket.current.emit("send-msg", {
          id: selectedChat.current.user_id,
          to: selectedChat.current.user_profile_id,
          from: data.user_profile_id,
          msg,
        });
      })
      .catch(error => {
        if (error.response && error.response.status) {
          Notification.show('error', error.response.data.message);
          console.log(error.response.data)
        }
      });

    socket.current.emit("active-users", (users) => {
      console.log('Chat.js Online Users==', JSON.parse(users))
      if (!JSON.parse(users)[selectedChat.current.user_id]) {
        ChatService.storeNotification({
          receiver_profile_id: selectedChat.current.user_profile_id,
          message: msg
        })
          .then(response => {
            console.log("Offline Notify==", response.statusText);
          })
          .catch(error => {
            if (error.response && error.response.status) {
              Notification.show('error', error.response.data.message);
              console.log(error.response.data)
            }
          });
      }
    });

    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg });
    setMessages(msgs);
  };

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (messageData) => {
        if (messageData.from == selectedChat.current.user_profile_id && messageData.to == currentUser.user_profile_id) {
          setArrivalMessage({ fromSelf: false, message: messageData.msg });
        }
      });
    }
  }, []);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messageFormat(messages).map((chat) => {
          return (<div key={uuidv4()}> <div className="date-head"><span className="date">{getDate(chat.date)}</span></div>
            {chat.messages.map((message) => {
              return (
                <div ref={scrollRef} key={uuidv4()}>
                  <div
                    className={`message ${message.fromSelf ? "sended" : "received"
                      }`}
                  >
                    <div className="content ">
                      <p>{message.message}</p>
                      <span className="time">{getTime(message.created)}</span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>)
        })}
      </div>
      <ChatInput handleSendMsg={handleSendMsg} currentChat={selectedChat.current} />
    </div>
  );
}