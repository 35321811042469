import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import ChatContainer from "../components/ChatContainer";
import Header from "../components/Header";
import Contacts from "../components/Contacts";
import Welcome from "../components/Welcome";
import ChatService from "../services/chatService";
import { config } from "../utils/Helpers"
import Notification from "../utils/notification";
import { checkSessionToken, getSessionToken } from "../utils/auth";

export default function Chat() {
  const navigate = useNavigate();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(async () => {
    if (!checkSessionToken()) {
      navigate("/home");
    } else {
      setCurrentUser(
        await getSessionToken()
      );
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(config('LOCALHOST'));
      socket.current.emit("add-user", currentUser.user_profile_id, currentUser.user_id);

      socket.current.on("online-users", (onlineUser) => {
        console.log('Chat.js Online Users==',  JSON.parse(onlineUser))
        setOnlineUsers(JSON.parse(onlineUser))
      });

    }
  }, [currentUser]);

  useEffect(async () => {
    if (currentUser) {
        ChatService.getUsers()
        .then(response => {
            setContacts(response.data.data);
        })
        .catch(error => {
            if (error.response && error.response.status) {
              Notification.show('error', error.response.data.message);
              console.log(error.response.data)
            }
        });
    }
  }, [currentUser]);

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  };
  return (
    <div className="main-container">
        <Header currentUser={currentUser}/>
        <div className="container">
          <Contacts contacts={contacts} changeChat={handleChatChange} onlineUsers={onlineUsers} />
          {currentChat === undefined ? (
            <Welcome />
          ) : (
            <ChatContainer currentChat={currentChat} socket={socket} currentUser={currentUser} />
          )}
        </div>
    </div>
  );
}