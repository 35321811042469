import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/Helpers';

class ChatService {
    static getUsers(query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/users'), requestOptions);
    }

    static getUser(query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/user'), requestOptions);
    }

    static storeNotification(data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        const body = JSON.stringify(data)

        return axios.post(apiRoute('/notifications'), body, requestOptions);
    }

    static getMessage(query) {
        const requestOptions = {
            headers: getApiHeader(),
            params: query
        };

        return axios.get(apiRoute('/message'), requestOptions);
    }

    static storeMessage(data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        const body = JSON.stringify(data)

        return axios.post(apiRoute('/message'), body, requestOptions);
    }
}

export default ChatService;