import axios from 'axios';
import Notification from './../utils/notification';
import AuthService from '../services/authService';
import { removeLocalStorage, setLocalStorage } from './auth';

export default function () {
    console.log('api error');
    const errorHandler = error => {
        const originalRequest = error.config;

        if (error.response && error.response.status) {
            if (error.response.status === 401) {
                let error_data = error.response.data;
                if (error_data.error_type === 'invalid_access_token') {
                    return new Promise((resolve, reject) => {
                        AuthService.getNewToken().then((response) => {
                            removeLocalStorage('token');
                            setLocalStorage('token', response.data.access_token);
                            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                            return resolve(axios.request(originalRequest));
                        }).catch((error) => {
                            console.log(error.response.data)
                            if (error.response && error.response.status) {
                                Notification.show('error', error.response.data.message);
                            }
                            localStorage.clear();
                            setTimeout(function () {
                                window.location.reload();
                            }, 5000);
                            return reject(error);
                        })
                    })
                } else if (error_data.error_type === 'expired_access_token') {
                    Notification.show('error', error.response.data.message);
                    localStorage.clear();
                    setTimeout(function () {
                        window.location.reload();
                    }, 5000);
                }
                return Promise.reject(error);
            }
            else {
                return Promise.reject(error);
            }
        } else {
            Notification.show('warning', 'Check your Internet connection and try again !');
            return Promise.reject(error);
        }
    };

    axios.interceptors.response.use(response => response, errorHandler);
}
