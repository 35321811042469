import React from "react";
import * as Helpers from "../utils/Helpers"

export default function Home() {

  const handleClick = () => {
    window.close()
    window.open(Helpers.config('DAISY_PROVIDER'))
  }
  return (
    <div className="auth-home">
      <h1>Welcome to Daisy Health Chat</h1>
      <h3>Visit Daisy Health Portal to continue.</h3>
      <a onClick={handleClick} className="cursor-pointer">Click here</a>
    </div>
  );
}