import * as encryptUtils from './encrypt';
import { config } from './Helpers';

export const setSessionToken = (data) => {
    const stringValue = JSON.stringify(data);

    const encryptedValue = encryptUtils.encryptLocal(stringValue);
    localStorage.setItem(config('LOCALHOST_KEY'), encryptedValue);
}

export const getSessionToken = () => {
    const encryptedValue = localStorage.getItem(config('LOCALHOST_KEY'));

    if (encryptedValue) {
        const stringValue = encryptUtils.decryptLocal(encryptedValue);
        return JSON.parse(stringValue);
    }

    return false;
}

export const checkSessionToken = () => {
    if (localStorage.getItem(config('LOCALHOST_KEY'))) {
        return true;
    }

    return false;
}

export const removeSessionToken = () => {
    localStorage.removeItem(config('LOCALHOST_KEY'));
}

export const setUserProfile = (data) => {
    const stringValue = JSON.stringify(data);

    const encryptedValue = encryptUtils.encryptLocal(stringValue);
    localStorage.setItem('_user_profile', encryptedValue);
}

export const getUserProfile = () => {
    const encryptedValue = localStorage.getItem('_user_profile');

    if (encryptedValue) {
        const stringValue = encryptUtils.decryptLocal(encryptedValue);
        return JSON.parse(stringValue);
    }

    return false;
}

export const checkUserProfile = () => {
    if (localStorage.getItem('_user_profile')) {
        return true;
    }

    return false;
}

export const removeUserProfile = () => {
    localStorage.removeItem('_user_profile');
}

export const setLocalStorage = (name, value, encrypt=true) => {
    const stringValue = JSON.stringify(value);

    const encryptedValue = encrypt ? encryptUtils.encryptLocal(stringValue) : value;
    localStorage.setItem(name, encryptedValue);
}

export const getLocalStorage = (name, decrypt=true) => {
    const encryptedValue = localStorage.getItem(name);

    if (encryptedValue) {
        const stringValue = encryptUtils.decryptLocal(encryptedValue);
        return decrypt ? JSON.parse(stringValue) : encryptedValue;
    }

    return false;
}

export const removeLocalStorage = (name) => {
    localStorage.removeItem(name);
}