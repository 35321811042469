import React, { useRef } from "react";
import Logo from "../assets/logo.svg";
import { io } from "socket.io-client";
import RoleService from '../services/roleService';
import { config } from "../utils/Helpers"

export default function Header({currentUser}) {
    const socket = useRef();

    const handleClick = async () => {
        if(window.confirm('Are you sure want to close?')){
            localStorage.clear();
            if (currentUser) {
                socket.current = io(config('LOCALHOST'));
                socket.current.emit("remove-user", currentUser.user_id, () => {
                    window.close();
                });
            }
        }
      };
    return (
        <div className="header">
                <div className="brand">
                    <img src={Logo} alt="logo" />
                    {currentUser && (<div className="current-user">
                        <ul className="clearfix">
                            <li>{currentUser.user.full_name}
                            <span className="sub-details">{currentUser.role_slug && RoleService.getRoleNameBySlug(currentUser.role_slug)} | {currentUser.service_provider.name} | {currentUser.role_slug === 'doctor' ? 'Owner' : currentUser.user_location.name}</span>
                            </li>
                            <li><a onClick={handleClick} className="cursor-pointer">
                            Sign Out
                            </a>
                            </li>
                        </ul>
                    </div>)}
                </div>
        </div>
    );
}
