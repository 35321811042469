import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChatService from "../services/chatService";
import { setLocalStorage, setSessionToken } from "../utils/auth";
import * as Helpers from "../utils/Helpers"
import Notification from "../utils/notification";
import AuthService from "../services/authService";

export default function Auth() {
  const params = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    if(params.token){
      setLocalStorage('token', params.token);
      params.id && setLocalStorage('notify_by_id', params.id, false);
      ChatService.getUser()
        .then(response => {
            if (response.data) {
              setSessionToken(response.data);
              AuthService.getTemporaryToken()
                .then(res => {
                  res.data && setLocalStorage('temporary_token', res.data.temporary_token);
                })
                .catch(error => {
                    if (error.response && error.response.status) {
                      Notification.show('error', error.response.data.message);
                      console.log(error.response.data)
                    }
                })
              navigate("/");
            }
        })
        .catch(error => {
            if (error.response && error.response.status) {
              Notification.show('error', error.response.data.message);
              console.log(error.response.data)
            }
        });
    }
  }, []);

  const handleClick = () => {
    window.close()
    window.open(Helpers.config('DAISY_PROVIDER'))
  }
  return (
    <div className="auth-home">
      <h1>Welcome to Daisy Health Chat</h1>
      {/* <h3>Visit Daisy Health Portal to continue.</h3> */}
      {/* <a onClick={handleClick} className="cursor-pointer">Click here</a> */}
    </div>
  );
}