import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/Helpers';
import { checkSessionToken, getLocalStorage, getSessionToken } from '../utils/auth';

class AuthService {
    static getTemporaryToken() {
        const requestOptions = {
            headers: getApiHeader()
        };

        const user_id = checkSessionToken() && getSessionToken().user_id;
        const body = user_id ? JSON.stringify({ user_id: user_id }) : "";

        return axios.post(apiRoute('/temporary-token'), body, requestOptions);
    }

    static getNewToken() {
        const requestOptions = {
            headers: getApiHeader(null, false),
        };

        const user_id = checkSessionToken() && getSessionToken().user_id;
        const temporary_token = getLocalStorage('temporary_token');

        const body = temporary_token ? JSON.stringify({
                    user_id: user_id,
                    temporary_token: temporary_token
                }) : "";        

        return axios.post(apiRoute('/refresh-token'), body, requestOptions);
    }
}

export default AuthService;