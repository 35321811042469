import moment from 'moment';
import { getLocalStorage } from './auth';

export function config(key) {
    return process.env['REACT_APP_' + key];
}

export function apiRoute($url) {
    return config('DAISY_API') + $url;
}

export function getApiHeader(extraHeader = {}, checkAuth = true) {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
    if (checkAuth) {
        headers.Authorization = 'Bearer ' + getLocalStorage('token');
    }

    return { ...headers, ...extraHeader };
}

export function createPaginateQueryString({ page = 1, sizePerPage = 25, sortBy = null, searchText = '', filter = null }) {
    let params = {
        page: page,
        limit: sizePerPage,
    };

    if (sortBy && sortBy.sortField) {
        let field = sortBy.sortField;
        let order = sortBy.sortOrder ? sortBy.sortOrder : 'asc';
        params.sort = field + ':' + order;
    }

    if (searchText) {
        params.search = searchText
    }

    if (filter) {
        Object.keys(filter).forEach(function (key) {
            params[key] = filter[key]
        });
    }

    return params;
}

export function fullNameStr(user) {
    if (user) {
        return user.name_title + ' ' + user.first_name + ' ' + user.last_name;
    }

    return '';
}

export function getDate(timestamp, format = 'DD MMM YYYY') {
    if (!timestamp) {
        return "";
    }
    var TODAY = moment().startOf('day');
    var YESTERDAY = moment().subtract(1, 'days').startOf('day');
    if (moment(timestamp).isSame(TODAY, 'd')){
        return 'Today';
    }
    if (moment(timestamp).isSame(YESTERDAY, 'd')){
        return 'Yesterday';
    }

    return moment(timestamp).format(format);
}

export function getTime(timestamp, format = 'hh:mm a') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function getCurrentUTCTime() {
    return new Date().toISOString();
}