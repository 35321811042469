import React, { useState, useEffect } from "react";
import RoleService from '../services/roleService';
import { getLocalStorage } from "../utils/auth";
import Avatar from "./Avatar";

export default function Contacts({ contacts, changeChat, onlineUsers }) {
  const [currentSelected, setCurrentSelected] = useState(undefined);

  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
  };

  const contactFormat = (contacts) => {
    // this gives an object with dates as keys
    const groups = contacts.reduce((groups, contact) => {
      const location = contact.user_location && contact.user_location.name.split('T')[0];
      if (!groups[location]) {
        groups[location] = [];
      }
      groups[location].push(contact);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((location) => {
      return {
        location,
        contacts: groups[location]
      };
    });

    return groupArrays;
  }

  useEffect( () => {
    let notify_by = getLocalStorage('notify_by_id', false);
    let contact = contacts.filter( (row) => row.user_profile_id === notify_by )[0];
    notify_by && changeCurrentChat(notify_by, contact);
  }, [contacts]);

  return (
    <div className="contacts-container">
          <div className="contacts">
          {contactFormat(contacts).map((chat,index) => {
            return (<div key={index}> <div className="location-head"><span className="location">{chat.location !== 'null' ? chat.location : 'Owner'}</span></div>
            <hr/>
            {chat.contacts.map((contact) => {
              return (
                <div
                  key={contact.user_profile_id}
                  className={`contact ${
                    contact.user_profile_id === currentSelected ? "selected" : ""
                  }`}
                  onClick={() => changeCurrentChat(contact.user_profile_id, contact)}
                >
                  <div className="username">
                    <Avatar name={contact.user.first_name} online={onlineUsers[contact.user_profile_id]}/>
                    <h3>{contact.user.full_name}
                    <small className="contact-sub-details">
                      {contact.role_slug && RoleService.getRoleNameBySlug(contact.role_slug)} {contact.user_location &&  " - " + contact.user_location.name}
                    </small>
                    </h3>
                  </div>
                </div>
              );
            })}
            </div>)
          })}
          </div>
    </div>
  );
}
