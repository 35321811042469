import React from "react";
import ReactDOM from "react-dom";
import initAxios from './utils/init-axios';
import App from "./App";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

initAxios();
ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer/>
  </React.StrictMode>,
  document.getElementById("root")
);
